<template lang="pug">
v-menu(
  v-bind="menuProps"
  :value="value"
  :transition="false"
  :activator="activator"
)
  template(#activator="{ on, attrs }")
    v-btn(
      v-bind="attrs"
      v-on="on"
      color="primary"
      outlined
      ) Действия
  v-card
    v-list
      v-list-item( @click="openCardModal" v-if="showAccountAction")
        v-list-item-title Просмотр аккаунта

      v-list-item( @click.stop="changeStatus" :disabled="isLoadingChangeStatus" )
        v-list-item-action
          v-switch(
            :input-value="item.isEnabled"
            :true-value="true"
            :false-value="false"
            :disabled="isLoadingChangeStatus"
            color="primary"
            @change="changeStatus"
            @click.stop
          )
        v-list-item-title {{item.isEnabled ? 'Деактивировать' : 'Активировать'}}

      v-list-item( @click.stop="resetPassword" :disabled="isLoadingResetPassword" )
        v-list-item-title Сбросить пароль

      v-list-item( @click="openEditProfileModal" )
        v-list-item-title Редактировать профиль

      v-list-item( @click="openBindingsModal" )
        v-list-item-title Редактировать привязки

      v-list-item( @click="openRoleModal" )
        v-list-item-title Сменить роль

      v-list-item( @click="openCreateCopyAccountModal" )
        v-list-item-title Создать копию

      v-list-item( @click="openDeleteModal" )
        v-list-item-title Удалить аккаунт
</template>

<script>
export default {
  props: {
    value: { type: Boolean, default: null },
    activator: { type: String, default: null },
    item: { type: Object, required: true },
    menuProps: { type: Object, default: null },
    showAccountAction: { type: Boolean, default: false },
  },
  data() {
    return {
      isLoadingResetPassword: false,
      isLoadingChangeStatus: false,
    };
  },

  methods: {
    openCardModal() {
      this.$openModal(
        () => import('@/views/accounts/modals/AccountModal.vue'),
        { id: this.item.id },
      );
    },

    openEditProfileModal() {
      this.$openModal(
        () => import('@/views/accounts/modals/EditProfileModal.vue'),
        { id: this.item.id },
      );
    },

    openCreateCopyAccountModal() {
      this.$openModal(
        () => import('@/views/accounts/modals/CreateAccountModal.vue'),
        { copyId: this.item.id },
      );
    },

    openBindingsModal() {
      this.$openModal(
        () => import('@/views/accounts/modals/AccountBindingsModal.vue'),
        { id: this.item.id },
      );
    },

    openRoleModal() {
      this.$openModal(
        () => import('@/views/accounts/modals/ChangeRoleModal.vue'),
        { id: this.item.id },
      );
    },

    openDeleteModal() {
      this.$openModal(() => import('@/views/accounts/modals/Delete.vue'), {
        id: this.item.id,
      });
    },

    async resetPassword() {
      try {
        this.isLoadingResetPassword = true;
        await this.$store.dispatch('ACCOUNTS/resetPassword', this.item.id);

        this.$notify({
          group: 'note',
          type: 'info',
          title: `Пароль для пользователя ${this.item.email} успешно сброшен`,
        });
      } catch (error) {
        this.$notify({
          group: 'error',
          type: 'error',
          title: 'Ошибка изменения пароля пользователя ' + this.item.email,
          text: error,
        });
      } finally {
        this.isLoadingResetPassword = false;
      }
    },

    async changeStatus() {
      try {
        this.isLoadingChangeStatus = true;
        await this.$store.dispatch('ACCOUNTS/changeStatus', {
          id: this.item.id,
          isEnabled: !this.item.isEnabled,
        });
        const status = this.item.isEnabled ? 'активирован' : 'деактивирован';

        this.$notify({
          group: 'note',
          type: 'info',
          title: `Пользователь ${this.item.email} успешно ${status}`,
        });
      } catch (error) {
        this.$notify({
          group: 'error',
          type: 'error',
          title: 'Ошибка изменения статуса пользователя ' + this.item.email,
          text: error,
        });
      } finally {
        this.isLoadingChangeStatus = false;
      }
    },
  },
};
</script>

<style></style>
