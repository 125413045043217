<template lang="pug">
ListPageLayout(title="Список аккаунтов")
  template(#filters)
    v-col(cols="12" sm="4" md="3")
      v-text-field(
        label="Поиск аккаунтов"
        placeholder="Поиск по Email, Фамилии"
        append-icon="fa-search"
        hide-details
        :disabled="listIsLoading"
        :clearable="listSearch != null"
        @click:clear="querySearchList('')"
        :value="listSearch"
        @input="querySearchList"
        ref="search"
        outlined
        dense
      )
    v-col(cols="12" sm="4" md="3")
      v-select(
        label="Статус аккаунта"
        v-model="isEnabled"
        :items="statusItems"
        item-value="value"
        item-text="name"
        :disabled="listIsLoading"
        hide-details
        outlined
        dense
        :menu-props="{ offsetY: true }"
      )
    v-col(cols="12" sm="4" md="3")
      v-select(
        v-model="roleId"
        label="Роли"
        :items="roles"
        outlined
        hide-details
        item-value="id"
        item-text="name"
        clearable
        :loading="isLoadingRoles"
        :disabled="listIsLoading"
        :menu-props="{ offsetY: true }"
        dense
      )

    v-col(cols="12" sm="12" md="3")
      v-btn.float-right(
        text
        @click="resetListFilters"
        :disabled="listIsLoading"
      ) Сбросить фильтры

  template(#actions)
    v-btn.mr-6(
      @click="openCreateAccountModal"
      color="primary"
      depressed
    ) Добавить

  template(#list)
    v-data-table(
      loading-text=""
      :headers="headers"
      :items="listItems"
      item-key="id"
      :items-per-page.sync="listLimit"
      :loading="listIsLoading"
      no-data-text="Нет аккаунтов по данным фильтрам"
      hide-default-footer
    )

      template(#progress)
        Spinner

      template(#top)
        //- Actions
        AccountMenu(
          v-if="item"
          :value="!!currentAccountId"
          :activator="`#menu-activator-${currentAccountId}`"
          :item="item"
          :menuProps="{offsetX:true, left: true}"
          showAccountAction
        )

      template(#header)
        div.hidden-xs-only
          PerPagePicker(
            v-model="listLimit"
            data-table
          )

      template(#item.roleId="{ item }")
        div.py-4
          div {{role(item.roleId, 'name')}}

      template(#item.actions="{ item }")
        v-tooltip(left)
          template(v-slot:activator="{ on }")
            v-btn(
              icon
              :id="`menu-activator-${item.id}`"
              @click="showMenu(item.id)"
            )
              v-icon(small) fa-ellipsis-v
          span Редактировать работника

  template(#footer)
    v-pagination(
      v-show="listShowPagination"
      v-model="listCurrentPage"
      :length="listTotalPages"
      total-visible="10"
    )
</template>

<script>
import ListPageLayout from '@/components/layouts/ListPageLayout';
import Spinner from '@/components/Spinner.vue';
import PerPagePicker from '@/components/controls/PerPagePicker';
import AccountMenu from '@/views/accounts/components/AccountMenu';
import { mapActions, mapGetters } from 'vuex';
import { STATUS } from '@/utils/constants.js';

import resourceListFactory from '@/utils/mixins/resourceListFactory';

export default {
  components: {
    ListPageLayout,
    Spinner,
    PerPagePicker,
    AccountMenu,
  },

  extends: resourceListFactory(
    'ACCOUNTS',
    ['roleId', 'isEnabled'],
    'аккаунтов',
  ),

  data() {
    return {
      statusItems: STATUS,
      headers: [
        { text: 'ID', value: 'id', sortable: false },
        { text: 'ФИО', value: 'name', sortable: false, width: 200 },
        {
          text: 'Организация',
          value: 'organization',
          sortable: false,
          width: 200,
        },
        { value: 'email', text: 'Email', sortable: false },
        {
          text: 'Статус',
          value: 'isEnabled',
          sortable: false,
          width: 200,
        },
        { text: 'Позиция', value: 'position', sortable: false },
        {
          text: 'Роль',
          value: 'roleId',
          sortable: false,
        },
        { text: 'Действия', value: 'actions', align: 'right', sortable: false },
      ],

      currentAccountId: null,
      editProfileDialog: false,

      isLoadingRoles: false,
    };
  },
  computed: {
    ...mapGetters('ACCOUNTS', ['role', 'roles']),

    item() {
      return this.listItem(this.currentAccountId);
    },
  },

  async created() {
    this.isLoadingRoles = true;
    try {
      await this.fetchRoles();
    } catch (error) {
      this.$notify({
        group: 'error',
        type: 'error',
        title: 'Ошибка загрузки ролей аккаунтов',
        text: error,
      });
    } finally {
      this.isLoadingRoles = false;
    }
  },

  mounted() {
    document.addEventListener('click', this.handleClick);
  },

  destroyed() {
    document.removeEventListener('click', this.handleClick);
  },

  methods: {
    ...mapActions('ACCOUNTS', ['resetListFilters', 'fetchRoles']),

    openCreateAccountModal(copyId) {
      this.$openModal(() =>
        import('@/views/accounts/modals/CreateAccountModal.vue'),
      );
    },

    async showMenu(id) {
      if (this.currentAccountId === id) {
        this.currentAccountId = null;
        return;
      }
      this.currentAccountId = null;

      await new Promise(resolve => setTimeout(resolve, 100));
      this.currentAccountId = id;
    },

    handleClick() {
      this.showMenu(this.currentAccountId);
    },

    showCreateDialog() {
      this.resetItem();
      this.$emit('showCreateDialog');
    },
  },
};
</script>
